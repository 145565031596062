<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="case-list">
        <div class="case-details-totle">
            <!-- <div class="wenti-list">
              <div>交易问题></div>
              <div>{{center.title}}</div>
            </div> -->
            
            <!-- <div>{{center.send_time}}</div> -->
            <div class="lisrcente" v-html="center.details.replace(/\n/g,'<br/>')" @click="htmlBoxClick($event)"></div>
             <el-image
                style="display: none;"
                ref="preview"
                class="hideImgDiv"
                :src="imgPreviewSrc[0]"
                :preview-src-list="imgPreviewSrc"
            ></el-image>
        </div>
      

    </div>
  </div>
</template>

<script>
  export default {
    name:"infodetails",
    props:{
      id:''
    },
    data () {
      return {
        input:'',
        mocten:[],
        show:false,
        center:{},
        imgPreviewSrc:""
      }
    },
    mounted () {
     console.log(this.id,'!!!!')
     this.getsousuolist()
    },
    methods: {
      // 图片点击放大
      htmlBoxClick(e){ 
        // console.log(e,'gggggg')
        if (e.target.nodeName === 'IMG'|| e.target.nodeName == 'img') { //判断是否图片标签
          const img = e.target.currentSrc //获取点击图片地址
          this.imgPreviewSrc=[img];
          this.$refs.preview.clickHandler();
          return false;
        }
      },
      getsousuolist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getHelpInfo?id="+ that.id,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  that.center = res.data.data
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    /* background-color: #F2F5FB ; */
  }
 
  .case-list{
    width: 100%;
     background-color: #fff;
     /* padding-top: 87px; */
  }
  .case-details-totle{
    
    min-height: 1090px;
  }
  .wenti-list{
    width: 96%;
    margin: 0 auto;
    display:flex;
    font-size:14px;
    padding:24px 0;
    border-bottom: 1px solid #999999;
  }
  .wenti-list div:nth-child(1){
    font-weight: 550;
     font-size: 14px;
     color: #999999;
   
    /* text-align: center; */
  }
  .wenti-list div:nth-child(2){
     font-size: 14px;
     color: #333333;
    /* padding: 20px 0; */
    /* text-align: center; */
  }
  .lisrcente{
    width: 90%;
    /* text-align: center; */
    margin: 25px auto;
    white-space: pre-wrap;
  }
  .lisrcente>>> img {
        max-width: 100% ;
  }

</style>


